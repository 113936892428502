
import { Component, Prop } from "vue-property-decorator";
import ListMixin from "@/mixins/Crud/ListMixin";
import DefaultTableLayout from "@/layout/DefaultTableLayout.vue";
import { Software } from "@/config/Modules";
import { mixins } from "vue-class-component";
import { SoftwareStoreMixin } from "@/mixins/Stores/BaseData/SoftwareStoreMixin";
import { ICrudOptions } from "@/interfaces/ICrudOptions";

@Component({
  components: { DefaultTableLayout },
  mixins: [ListMixin],
})
export default class SoftwareList extends mixins(
  SoftwareStoreMixin,
  ListMixin
) {
  @Prop({ required: false, default: "Dashboard" })
  public redirectRouteNameOnDeleteSuccess!: string;

  @Prop({ required: false, default: "name" })
  public descriptionField!: string;

  protected get resource(): string {
    return Software.resource;
  }

  protected loadList(options: ICrudOptions): any {
    return this.findAllSoftwareItemsAction(options);
  }

  protected init() {
    // bla
  }
}
